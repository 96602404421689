import { debounce } from '../helper/global.js';

class FeaturedProductGrid extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    this.shadowRoot.innerHTML = `
      <style>
        :host {
          display: contents;
        }
      </style>
      <slot></slot>
    `;

    // Handle resize for mobile/desktop switches
    this.resizeObserver = new ResizeObserver(
      debounce(() => {
        this.reloadProducts();
      }, 250)
    );
    this.resizeObserver.observe(document.body);
  }

  disconnectedCallback() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  async reloadProducts() {
    const productGrids = [this.querySelector('#product-grid'), this.querySelector('#product-grid-mobile')].filter(
      Boolean
    ); // Remove any null elements if one grid doesn't exist

    if (productGrids.length === 0) return;

    try {
      const response = await fetch(window.location.href);
      const html = await response.text();
      const parser = new DOMParser();
      const newDoc = parser.parseFromString(html, 'text/html');

      productGrids.forEach((grid) => {
        const newGrid = newDoc.querySelector(`#${grid.id}`);
        if (newGrid) {
          grid.innerHTML = newGrid.innerHTML;
        }
      });
    } catch (error) {
      console.error('Error reloading products:', error);
    }
  }
}

customElements.define('featured-product-grid', FeaturedProductGrid);
